<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("RESELLERS.RESELLERS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openResellerCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_RESELLERS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("RESELLERS.ADD_RESELLER") }}
              </span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_RESELLERS)"
              :objectType="'resellers'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <reseller-list-table
        @onViewReseller="openResellerViewModal"
        @onEditReseller="openResellerEditModal"
        @onDeleteReseller="deleteReseller"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewResellerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewResellerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLERS.VIEW_RESELLER") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openReseller"
                :objectType="'resellers'"
                :objectId="openReseller.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeResellerModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-reseller-page
              v-if="openReseller"
              :resellerId="openReseller.id"
              @onEditReseller="openResellerEditModal"
              @onDeleteReseller="deleteReseller"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditResellerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditResellerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'RESELLER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLERS.EDIT_RESELLER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openResellerViewModal(openReseller)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>

              <button class="close" @click="closeResellerModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <edit-reseller-page
              v-if="openReseller"
              :resellerId="openReseller.id"
              @onViewReseller="openResellerViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddResellerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddResellerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'RESELLER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("RESELLERS.ADD_RESELLER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeResellerModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reseller-page @onViewReseller="openResellerViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ResellerListTable from "./partials/ResellerListTable.vue";
import EditResellerPage from "./components/EditResellerComponent.vue";
import AddResellerPage from "./components/AddResellerComponent.vue";
import ViewResellerPage from "./components/ViewResellerComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ResellerListTable,
    NotificationSubscription,
    EditResellerPage,
    AddResellerPage,
    ViewResellerPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const resellerId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewResellerModalOpened = false;
    let isEditResellerModalOpened = false;
    let isAddResellerModalOpened = false;
    let openReseller = null;
    if (resellerId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewResellerModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditResellerModalOpened = true;
      }
      openReseller = { id: resellerId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddResellerModalOpened = true;
    }
    return {
      isViewResellerModalOpened: isViewResellerModalOpened,
      isEditResellerModalOpened: isEditResellerModalOpened,
      isAddResellerModalOpened: isAddResellerModalOpened,
      openReseller: openReseller,
      renderKey: 1,
    };
  },

  methods: {
    openResellerCreateModal() {
      this.closeResellerModal();
      this.isAddResellerModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Resellers",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openResellerViewModal(reseller, reRender = false) {
      this.closeResellerModal();
      this.openReseller = reseller;
      this.isViewResellerModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Resellers",
          query: { id: this.openReseller.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openResellerEditModal(reseller) {
      this.closeResellerModal();
      this.openReseller = reseller;
      this.isEditResellerModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Resellers",
          query: { id: this.openReseller.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeResellerModal() {
      this.isAddResellerModalOpened = false;
      this.isViewResellerModalOpened = false;
      this.isEditResellerModalOpened = false;
      this.openReseller = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Resellers",
          query: {},
        }).href
      );
    },

    async deleteReseller(reseller) {
      const confirmation = await swal.fire({
        text: this.$t("RESELLERS.DELETE_THIS_RESELLER"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("resellers/destroy", reseller.id);
          this.renderKey++;
          this.closeResellerModal();
          this.$notify({
            type: "success",
            message: this.$t("RESELLERS.RESELLER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
