<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ reseller.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_RESELLERS)"
            @click="deleteReseller"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_RESELLERS)"
            @click="editReseller"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <img
        v-if="reseller.logo"
        :src="`${reseller.logo}`"
        class="all-infos-image"
        alt="logo"
      />

      <img
        v-else
        class="all-infos-image default"
        src="/img/add-image.svg"
        alt="logo"
      />

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.INFORMATIONS_SUR_L_ORGANISATION") }}
        </p>
      </div>

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ reseller.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="reseller.excerpt"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ reseller.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              reseller.phone_type
                ? $t(`COMMON.PHONE_TYPE_${reseller.phone_type}`)
                : null
            }}:
            {{ reseller.phone }}
            {{
              reseller.phone_extension
                ? ` (ext: ${reseller.phone_extension})`
                : null
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span v-for="(line, index) in reseller.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ reseller.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.OWNER") }}</dt>
          <dd>
            <router-link :to="$objectViewRoute(reseller.owner)">
              {{
                `${reseller.owner.firstname} ${reseller.owner.lastname} - ${reseller.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="reseller.created_at">
            {{ $formatDate(reseller.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="reseller.updated_at">
            {{ $formatDate(reseller.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("RESELLERS.CONFIG_MANAGER") }}
        </p>
      </div>

      <img
        v-if="reseller.config_manager_app_logo"
        :src="`${reseller.config_manager_app_logo}`"
        class="all-infos-image"
        alt="logo"
      />

      <img
        v-else
        class="all-infos-image default"
        src="/img/placeholder.jpg"
        alt="logo"
      />

      <div class="all-infos-text">
        <dl class="row">
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_APP_NAME") }}
          </dt>
          <dd>
            {{ reseller.config_manager_app_name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_URL_REGEX") }}
          </dt>
          <dd>
            {{ reseller.config_manager_url_regex }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    editReseller() {
      this.$emit("onEditReseller", this.reseller);
    },
    deleteReseller() {
      this.$emit("onDeleteReseller", this.reseller);
    },
  },

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
